<template>
  <div style="width:100%;overflow-x:hidden;position:relative;background-color: #fff;">
    <img style="width:100%" :src="bannerlink"/>
  </div>
</template>

<script>
import bannerlink from '@/assets/bannerlink.png';

export default {
  name: "index",
  data() {
    return {
      bannerlink
    }
  },
  // mounted(){
  //   document.addEventListener('WeixinOpenTagsError', function (e) {
  //     console.error(e.detail.errMsg); // 无法使用开放标签的错误原因，需回退兼容。仅无法使用开发标签，JS-SDK其他功能不受影响
  //   })
  // },
  methods:{
    
  },
  components: {
  }
}
</script>

<style scoped>

</style>