<template>
  <div style="width:100%;overflow-x:hidden;">
    <img style="width:100%" :src="xbsx" />
  </div>
</template>

<script>
  import xbsx from '@/assets/xbsx.jpg'
    export default {
        name: "xbsx",
        data(){
            return {
                xbsx
            }
        }
    }
</script>

<style scoped>

</style>